import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from '../Component/Loading';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const RptSaleItemSummary = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const usershopdeptcode = appState?.[0]?.deptcode || 0;
    const usershopidref = appState?.[0]?.hotelshopid || 0;

    const queryConditionDishtype = usershopidref !== 0 ? `${usershopdeptcode}/Dishtypeloc` : "Dishtype";
    const queryConditionDishHead = usershopidref !== 0 ? `finddishtypeLocReport/${usershopdeptcode}` : "finddishtypeReport";

    const [RoomType, setRoomType] = useState([]);

    const [dhcodeMaster, setdhcodeMaster] = useState([]);
    const [dtcodeMaster, setdtcodeMaster] = useState([]);

    const [cmbAccdata, setcmbAccdata] = useState([]);
    const [cmbAccdataBank, setcmbAccdataBank] = useState([]);
    const [Room, setRoom] = useState([]);
    const [data, setData] = useState([]);
    const [billdata, setbilldata] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 0,
        deptcode: 0,
        dhcode: 0,
        dtcode: 0,
        pay1acc: 0,
        pay2acc: 0,
    });
    const [listVisible, setListVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemdetail, setitemdetail] = useState(0);
    const [query, setQuery] = useState('');
    const inputRef = useRef(null);

    const queryCondition = usershopidref !== 0 ? usershopidref : usershopid;
    const fetchData = async () => {
        if (usershopid != 0) {
            const responseDT = await fetch(`${hostlink}/${queryCondition}/${queryConditionDishtype}`);
            const jsonDataDT = await responseDT.json();
            setdtcodeMaster(jsonDataDT);

            console.log(`${hostlink}/${queryCondition}/${queryConditionDishHead}`)
            const responseDH = await fetch(`${hostlink}/${queryCondition}/${queryConditionDishHead}`);
            const jsonDataDH = await responseDH.json();
            setdhcodeMaster(jsonDataDH);
        }
        if (usershopidref == 0) {
            const response = await fetch(`${hostlink}/${usershopid}/item`);
            const jsonData = await response.json();
            setData(jsonData);
        } else {
            const response = await fetch(`${hostlink}/findNamebyStoreLevel/${usershopid}/${queryCondition}/${usershopdeptcode}`);
            const jsonData = await response.json();
            setData(jsonData);
        }
    };
    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
            event.target.setSelectionRange(0, event.target.value.length); // Select the input value
        }
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {

            } else if (event.keyCode === 9) {

            }
        }
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };
    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.itname && item.itname.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].itname);
            const queryCondition = filteredData[selectedIndex].ispackage == 0
                ? filteredData[selectedIndex].restrate
                : 0;

            setitemdetail(filteredData[selectedIndex].id);

        }
    };
    const handleItemClick = (index) => {
        const queryCondition = filteredData[index].ispackage == 0
            ? filteredData[index].restrate
            : 0;

        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].itname);
        setitemdetail(filteredData[index].id);

    };
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    useEffect(() => {
        fetchData();
    }, [usershopid]);

    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, pay1acc, pay2acc, deptcode, dhcode, dtcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async () => {
        setIsLoading(true);
        try {
            const queryCondition = document.getElementById("inputname1111").value === "" ? 0 : itemdetail;
            // console.log(`${hostlink}/${usershopid}/${usershopidref}/billviewPartyFilterItemwiseSummaryDTDH/${status}/${fromdate}/${todate}/${pay1acc}/${pay2acc}/${queryCondition}/${dhcode}/${dtcode}`)
            const billResponse = await axios.get(`${hostlink}/${usershopid}/${usershopidref}/billviewPartyFilterItemwiseSummaryDTDH/${status}/${fromdate}/${todate}/${pay1acc}/${pay2acc}/${queryCondition}/${dhcode}/${dtcode}`);
            const billData = billResponse.data;
            setbilldata(billResponse.data);
            
            const accResponse = await axios.get(`${hostlink}/${usershopid}/accmas`);
            const accmasData = accResponse.data;

            // Create a map for accmas by id
            const accmasMap = accmasData.reduce((acc, curr) => {
                acc[curr.id] = curr.accname;
                return acc;
            }, {});

            // Initialize containers for the sums and a Set to track unique shopvno values
            const pay1Sums = {};
            const pay2Sums = {};
            const shopvnoSet = new Set();

            // Calculate sums for pay1amt and pay2amt, skipping duplicates
            billData.forEach((item) => {
                const { SaleMasDTO } = item;

                // Skip if shopvno is already processed
                if (shopvnoSet.has(SaleMasDTO.shopvno)) {
                    return;
                }

                // Add shopvno to the Set to mark it as processed
                shopvnoSet.add(SaleMasDTO.shopvno);

                // Pay1 calculation
                if (SaleMasDTO.pay1amt > 0 && accmasMap[SaleMasDTO.pay1code]) {
                    const accname = accmasMap[SaleMasDTO.pay1code];
                    pay1Sums[accname] = (pay1Sums[accname] || 0) + SaleMasDTO.pay1amt;
                }

                // Pay2 calculation
                if (SaleMasDTO.pay2amt > 0 && accmasMap[SaleMasDTO.pay2code]) {
                    const accname = accmasMap[SaleMasDTO.pay2code];
                    pay2Sums[accname] = (pay2Sums[accname] || 0) + SaleMasDTO.pay2amt;
                }
            });

            // Combine pay1Sums and pay2Sums into a single container
            const combinedSums = { ...pay1Sums };
            Object.keys(pay2Sums).forEach((key) => {
                combinedSums[key] = (combinedSums[key] || 0) + pay2Sums[key];
            });

            setTotalSums(combinedSums);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Data Not Found...");
        }
    };



    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Sale Report Item-wise.xlsx');
    };
    useEffect(() => {
        // axios.get(`${hostlink}/${usershopid}/accmas`).then((Response) => { if (Response.data.length > 0) setcmbAccdata(Response.data); }).catch((Response) => { "data Not Found" })
        // axios.get(`${hostlink}/${usershopid}/accmas`).then((Response) => { setcmbAccdataBank(Response.data); }).catch((Response) => { "data Not Found" })
    }, [usershopid])

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-3 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode'> Sale Report Item-wise </span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Status:</label>
                    </div>
                    <div className='col-md-2'>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e) }} name='status' value={status} className="form-select">
                            {/* <option value={3}>All</option> */}
                            <option value={1}>Trash</option>
                            <option value={2}>Cancelled</option>
                            <option value={0}>Billed</option>

                        </select>
                    </div>
                    <div className='col-md-3 align-self-center text-center mt-2'>

                    </div>
                    <div className='col-md-1'>
                        <label htmlFor="roomtype" className="form-label">Sub Cat :</label>
                    </div>
                    <div className='col-md-2'>
                        <select id="dhcode" onChange={(e) => { onChangevalue(e) }} value={dhcode} name='dhcode' class="form-select" >
                            <option selected key={0} value={0}>All</option>
                            {dhcodeMaster.map((x) => { return (<option key={x.id} value={x.id} title={x.id}> {x.dhname} </option>) })}
                        </select>
                    </div>
                    <div className='col-md-1'>
                        <label htmlFor="roomtype" className="form-label">Main-Cat :</label>
                    </div>
                    <div className='col-md-2'>
                        <select id="dtcode" onChange={(e) => { onChangevalue(e) }} value={dtcode} name='dtcode' class="form-select" >
                            <option selected key={0} value={0}>All</option>
                            {dtcodeMaster.map((x) => { return (<option key={x.id} value={x.id} title={x.id}> {x.dtname} </option>) })}
                        </select>
                    </div>



                    <div className='col-md-4 align-self-center text-center mt-2'>

                    </div>
                    {/* <div className='col-md-1 align-self-center text-center mt-2'>
                        <label htmlFor="roomtype" className="form-label">Pay 1:</label>
                    </div>
                    <div className='col-md-2 mt-2'>
                        <select id="pay1acc" onChange={(e) => { onChangevalue(e) }} value={pay1acc} name='pay1acc' class="form-select" >
                            <option selected key={0} value={0}>All</option>
                            {cmbAccdata.map((x) => { return (<option key={x.id} value={x.id} title={x.id}> {x.accname} </option>) })}
                        </select>
                    </div>

                    <div className='col-md-1 align-self-center text-center mt-2'>
                        <label htmlFor="roomtype" className="form-label">Pay 2:</label>
                    </div>
                    <div className='col-md-2 mt-2'>
                        <select id="pay2acc" onChange={(e) => { onChangevalue(e) }} value={pay2acc} name='pay2acc' class="form-select" >
                            <option selected key={0} value={0}>All</option>
                            {cmbAccdataBank.map((x) => { return (<option key={x.id} value={x.id} title={x.id}> {x.accname} </option>) })}
                        </select>
                    </div> */}


                    <div className="col-md-5 mt-2">

                        <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} autoComplete='off' /> <br />
                        {listVisible && filteredData.length > 0 && (
                            <ul style={{}}>
                                {filteredData.map((item, index) => (
                                    <li
                                        key={item.id}
                                        onClick={() => handleSelectItem(item, index)}
                                        onMouseDown={() => handleItemClick(index)}

                                        className={index === selectedIndex ? 'selected' : ''}
                                    >
                                        {item.itname} - ₹{item.restrate}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>

                    <div className='col-md-3 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show</button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={billdata} fromdate={fromdate} todate={todate} />} fileName="Sale Report Item-wise.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center' >
                        <tr >
                            <th scope="col">Sr No</th>
                            <th scope="col">Item Name</th>
                            <th scope="col">Unit</th>
                            <th scope="col" className='text-end'>Qty</th>
                            <th scope="col" className='text-end'>Rate</th>
                            <th scope="col" className='text-end'>Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {billdata.map((res, x) => {
                            return (
                                <tr key={x} >
                                    <td>{x + 1}</td>
                                    <td>{res.itname}</td>
                                    <td>{res.unitname}</td>
                                    <td className='text-end'>{res.qty}</td>
                                    <td className='text-end'>{res.rate}</td>
                                    <td className='text-end'>{Number(res.qty * res.rate).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        <tr class="table-info">
                            <td></td>
                            <td></td>
                            <td><h6>Total :</h6></td>
                            <td className='text-end'><h6>{billdata.reduce((total, res) => total + parseFloat(Number(res.qty)), 0)}</h6></td>
                            <td></td>
                            <td className='text-end'><h6>{billdata.reduce((total, res) => total + parseFloat(Number(res.qty * res.rate)), 0)}</h6></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading />}
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Sale Report Item-wise</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Sr No</Text>
                            <Text style={styles.headerCell}>Item Name</Text>
                            <Text style={styles.headerCell}>Unit</Text>
                            <Text style={styles.headerCell}>Qty</Text>
                        </View>
                        {data.map((res, x) => (
                            <View key={x} style={styles.tableRow}>
                                <Text style={styles.cell}>{x + 1}</Text>
                                <Text style={styles.cell}>{res.itname}</Text>
                                <Text style={styles.cell}>{res.unitname}</Text>
                                <Text style={styles.cell}>{res.qty}</Text>
                            </View>
                        ))}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>

                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total:</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.qty)), 0)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});


export default RptSaleItemSummary
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
const RestBillViewCancelled = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        statusupdate: ""
    })
    const [AccCode, setAccCode] = useState([])
    const { deleteid, deletemsg,statusupdate } = messagedata;
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [blno, setblno] = useState(0)
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const handleOpenModal = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint,statusupdate:1 }); setIsModalOpen(true); };
    const handleOpenModaledit = (e) => { setblno(e); setIsModalOpenEdit(true); };
    const handleOpenModalCancel = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint,statusupdate:2 }); setIsModalOpenCancel(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const handleCloseModalEdit = () => { setIsModalOpenEdit(false); window.location.assign("/SaleBill") };
    const handleNoClickEdit = () => { console.log('User clicked No'); handleCloseModalEdit(); };
    var billgst;

    var billgst;
    const [logroom, setLogroom] = useState();
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);

    let bl_Tot_1 = 0;
    let bl_RoomRent = 0;
    let bl_Disc = 0;
    let bl_total = 0;
    let bl_gstTotal = 0;
    let bl_blamt = 0;

    const [roomdetail, setroomdetail] = useState([]);
    const today = new Date();
    const [refreshData, setRefreshData] = useState(false)
    const api = "/bill";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [roomrentcalculation, setroomrentcalculation] = useState([]);
    const [newItemdata, setItemdata] = useState({ chdate: '', rtype: 0, roomtypeview: '', roomnoview: '', rcode: 0, rent: 0, exbed: 0, exbedch: 0, chid: 0 });
    const Closeform = () => {
        window.location.assign("/SaleBill")
    }
    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate: today.toISOString().split('T')[0]
    })
    const { blfromdate, bltodate } = viewbldate;
    const bldatechange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setBldate({ ...viewbldate, [name]: value })
        setRefreshData(true)
    }
    const [logdetail, setlogdetail] = useState({
        onform: "SALE",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const handleCheckout = async (e, id, rnamedata) => {

        await updateStatus(id); // Pass both the event object and the ID to updateStatus
        handleOpenModaledit(rnamedata)
        // billtotal(); // Call billtotal after updating status


    };
    const getrent = (e) => {
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {

                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                setSavedata(ld);
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e) => {

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        } else {
            axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}/${e}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        }
    }

    const saveBill = async (e) => {
        const rnameString = roomdetail.map(item => item.roomnoview).join(',');
        const rcodeString = roomdetail.map(item => item.rcode).join(',');

        var ld = { ...billdata, roomdescription: rnameString, roomdescriptioncode: rcodeString, narration: usershopid + document.getElementById("checkoutdate").value.trim() + document.getElementById("inputname").value.trim() }
        await axios.put(`${hostlink}/bill`, ld)
            .then((respbill) => {
                var billno;
                billno = (respbill.data)
                var ld = {
                    ...logdetail,
                    newdesc: `Bill No : ${respbill.data}, Mob No : ${guestmob}, Name : ${guestname}`,
                    operation: "Bill", olddesc: "",
                }; setlogdetail(ld); saveLog(ld)
                // axios.post(`${hostlink}/checkin/blno/${respbill.data}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/bldate/${checkoutdate}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/statusbill/${checkinid}`)
                // const rcodeString = roomdetail.map(item => item.rcode).join(',');
                // axios.post(`${hostlink}/room/updateroombulk/0/${rcodeString}`)
                toast("Save Sucessfull")
                setTimeout(() => {
                    window.location.assign("/SaleBill")
                }, 1000);
            })
            .catch(() => {
                console.log("Data Not Save")
            })
    }

    
    const [savedata, setSavedata] = useState({
        id: "",
        indate: "",
        mobno: "",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: "",
        rcode: "",
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        acccode: 0
    })

    const { mobno, gname, add1, add2, documentno, documentpath, indate, email, gstno, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, stday, discount, advance, paidby, remarks } = savedata;
    const [billdata, setBilldata] = useState({
        id: "",
        checkindate: "",
        checkoutdate: today.toISOString().split('T')[0],
        bldate: today.toISOString().split('T')[0],
        roomcode: "",
        guestroomname: "",
        roomrent: 0,
        extrabed: 0,
        extrabedch: 0,
        discountperc: 0,
        adddiscountamt: 0,
        additionalcharge: 0,
        advanceamount: 0,
        blpaidby: "",
        guestmob: "",
        guestname: "",
        guestadd1: "",
        guestadd2: "",
        guestdocno: "",
        guestemail: "",
        guestgstno: "",
        guestcompanyname: "",
        blremarks: "",
        bltotaldays: "",
        bltotal: "",
        blroomrent: "",
        discountpercamt: "",
        blgstperc: "",
        guestroomname: "",
        blgstamt: "",
        blamt: "",
        netpayamt: "",
        checkinid: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomdescription: "",
        roomdescriptioncode: "",
        acccode: 0,
        roomserviceamt: 0
    })
    const { checkinid, checkindate, acccode, checkoutdate, roomcode, roomrent, extrabed, extrabedch, discountperc, roomserviceamt, adddiscountamt, additionalcharge, advanceamount, blpaidby, guestmob, guestname, guestadd1, guestadd2, guestdocno, guestemail, guestgstno, guestcompanyname, blremarks, bltotaldays, bltotal, blroomrent, discountpercamt, blgstperc, blgstamt, blamt, netpayamt } = billdata;


    const billtotal = (e) => {
        var value = bl_Tot_1
        var ld = {
            ...billdata, bltotaldays: bl_Tot_1,
            bltotal: (value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100),
            bldate: document.getElementById("checkoutdate").value,
            blroomrent: bl_RoomRent,
            discountpercamt: bl_Disc,
            blgstamt: bl_gstTotal,
            bltotal: (bl_RoomRent - bl_Disc),
            blamt: ((((bl_RoomRent - bl_Disc) + (bl_gstTotal) + roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)),
            netpayamt: (((((bl_RoomRent - bl_Disc) + (bl_gstTotal) + roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)) - Number(advanceamount))
        }
        setBilldata(ld);
        setIsModalOpenEdit(false);
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const onChangevaluebill = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setBilldata({ ...billdata, [name]: sanitizedValue });
    }

    const updateStatus = async (e) => {
        let x = document.getElementById("frm_checkoutdetail")
        x.style.display = "block"
        await axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setBilldata(Response.data)
                console.log(Response.data)
              
                var ld = {
                    ...logdetail,
                    olddesc: `Mob No : ${Response.data.mobno}, Name : ${Response.data.gname}, Rent : ${Response.data.rent}, Ex Bed : ${Response.data.exbed}, Bed Ch : ${Response.data.exbedch} , Advance : ${Response.data.advance}`,
                    operation: "Check-Out"
                }
                setlogdetail(ld);

                axios.get(`${hostlink}/getchehinroomDetail/${Response.data.checkinid}`)
                    .then((resp) => {
                        setroomdetail(resp.data)

                    })
            })

            .catch((res) => { console.log("Data Not find") })
    }
    const deleteData = async (e) => {
            await axios.delete(`${hostlink}/Restbillviewdelete/${usershopid}/${deletemsg}`)
                .then((responce) => {
                    toast.success("Delete Sucessfull")
                    setInterval(() => {
                        handleCloseModal();
                        window.location.assign("/RestBillViewTrash")
                        setRefreshData(true)
                    }, 1000);
                })
                .catch((resp)=>{console.log("Data Not Fatch..")})
    }
    const cancelBill = async (e) => {
        await axios.post(`${hostlink}/Restbillview/${usershopid}/${deletemsg}/0`)
            .then((Response) => {
                handleCloseModalCancel();
                toast.success("Restore-Bill Sucessfull")
                setRefreshData(true)
                console.log(Response.data)
            })
            .catch(() => {

            })
        //  setRefreshData(true)
    }
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        
        setRefreshData(false)
        axios.get(`${hostlink}/${usershopid}/Restbillview/2/${blfromdate}/${bltodate}`)
            .then((Response) => {
                console.log("Data Fatched")
                
                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/${usershopid}/accmas`)
            .then((Response) => {
                setAccCode(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }, [refreshData,usershopid])
    return (
        <div className='p-1 ' >
            
            {/* /// Check In  */}
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode' > Check-OUT [ New ]</span></h5>
                
                <div class="col-md-12 text-end">
                    
                   
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                    <h5 className='font-monospace'><i class="fa-solid fa-ban"></i> Cancelled Bill</h5>
                    </div>
                    <div className='col-md-3 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />

                    </div>

                    <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                        </span>
                        <span> <input type="date" id="blfromdate" name="blfromdate" onChange={(e) => { bldatechange(e); }} value={blfromdate} className="form-control" /></span>


                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="bltodate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                        </span>
                        <span>
                            <input type="date" id="bltodate" name="bltodate" onChange={(e) => { bldatechange(e); }} value={bltodate} className="form-control" />
                        </span>
                    </div>


                    <div style={{ height: "80vh", verticalAlign: 'middle', height: '100%' }} className='col-12 '>

                        <table className="table table-hover table-striped" >
                            <thead>
                                <tr style={{ height: '100%' }}>
                                    
                                    <th scope="col" className="f-size-on-mobile" >BlNo</th>
                                    <th scope="col" className="f-size-on-mobile">Bill <span className="hide-on-mobile" >Date</span>  <span className="detail-on-mobile"> Details</span> </th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    {/* <th scope="col" className="hide-on-mobile">Type</th> */}
                                    {/* <th scope="col" className="hide-on-mobile">Table</th> */}
                                    <th scope="col" className="hide-on-mobile">Bill Amount</th>
                                    <th scope="col" className="hide-on-mobile">Remarks</th>
                                    <th scope="col" className="f-size-on-mobile align-self-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                        <td className="f-size-on-mobile align-self-center text-center" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.shopvno}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <span className="detail-on-mobile"><b> Bill Date : {res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>  <span className='hide-on-mobile'>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Ord Type : {res.bltype} <br />
                                                Guest : {res.SaleMasDTO.custname} <br /> Mob : {res.SaleMasDTO.custmob} <br /> Table : {res.tablename},
                                                Gst Amount : {res.SaleMasDTO.totgst}  <br />
                                                <span style={{ color: "red" }}> <b>Bill Amount : {res.SaleMasDTO.totblamt} </b></span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.entrytime}</td>
                                        {/* <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.bltype}</td> */}
                                        {/* <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.tablename}</td> */}
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.totblamt}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.narration}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>

                                            
                                            {/* <Link onClick={(e) => { handleOpenModal(res.SaleMasDTO.id, res.SaleMasDTO.shopvno,1) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link>&nbsp; <div className='show-on-mobile'><p></p></div> */}
                                            {/* <Link className='btn btn-sm btn-outline-primary'><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp; */}
                                            <Link onClick={(e) => { handleOpenModalCancel(res.SaleMasDTO.id, res.SaleMasDTO.shopvno,2) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-trash-can-arrow-up"></i>  <span className="hide-on-mobile"></span></Link>&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpenEdit} onRequestClose={handleCloseModalEdit} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Edit Bill ? <br /> Room No : {blno} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { billtotal(1); }}>Yes</button> <button className="no-button" onClick={handleNoClickEdit}>No</button></div></div>
            </Modal>


            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this Bill No : {deletemsg}?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCancel} onRequestClose={handleCloseModalCancel} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div clas><p className='text-bg-danger'>Are you sure you want to Restore this Bill No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cancelBill(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClickCancel}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default RestBillViewCancelled

import React, { useState, useEffect } from "react";

const BluetoothPrinter = () => {
  const [printerDevice, setPrinterDevice] = useState(null);
  const [printCharacteristic, setPrintCharacteristic] = useState(null);
  const [deviceName, setDeviceName] = useState("");
  const [characteristicUUID, setCharacteristicUUID] = useState("");
  const [isPrinterConnected, setIsPrinterConnected] = useState(false);

  useEffect(() => {
    const savedDeviceName = localStorage.getItem("deviceName");
    const savedCharacteristicUUID = localStorage.getItem("characteristicUUID");

    if (savedDeviceName && savedCharacteristicUUID) {
      setDeviceName(savedDeviceName);
      setCharacteristicUUID(savedCharacteristicUUID);
      console.log("Stored printer details loaded.");
    }
  }, []);

  const searchPrinter = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: ["00001800-0000-1000-8000-00805f9b34fb"],
      });

      const server = await device.gatt.connect();
      console.log("Connected to printer:", device.name);

      const services = await server.getPrimaryServices();
      let writableCharacteristic = null;

      for (let service of services) {
        const characteristics = await service.getCharacteristics();
        for (let characteristic of characteristics) {
          if (characteristic.properties.write) {
            writableCharacteristic = characteristic;
            console.log("Writable characteristic found:", characteristic.uuid);
          }
        }
      }

      if (writableCharacteristic) {
        setPrinterDevice(device);
        setPrintCharacteristic(writableCharacteristic);
        setDeviceName(device.name);
        setCharacteristicUUID(writableCharacteristic.uuid);
        setIsPrinterConnected(true);

        localStorage.setItem("deviceName", device.name);
        localStorage.setItem("characteristicUUID", writableCharacteristic.uuid);

        alert(`Connected to printer: ${device.name}`);
      } else {
        alert("Writable characteristic not found!");
      }
    } catch (error) {
      alert("Failed to connect to printer: " + error.message);
    }
  };

  const reconnectPrinter = async () => {
    const savedDeviceName = localStorage.getItem("deviceName");
    const savedCharacteristicUUID = localStorage.getItem("characteristicUUID");

    if (!savedDeviceName || !savedCharacteristicUUID) {
      alert("No printer details found. Please connect a printer first.");
      return;
    }

    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ name: savedDeviceName }],
        optionalServices: ["00001800-0000-1000-8000-00805f9b34fb"],
      });

      const server = await device.gatt.connect();
      console.log("Reconnected to printer:", savedDeviceName);

      const services = await server.getPrimaryServices();
      let writableCharacteristic = null;

      for (let service of services) {
        const characteristics = await service.getCharacteristics();
        for (let characteristic of characteristics) {
          if (characteristic.uuid === savedCharacteristicUUID) {
            writableCharacteristic = characteristic;
            console.log("Reconnected writable characteristic:", savedCharacteristicUUID);
          }
        }
      }

      if (writableCharacteristic) {
        setPrinterDevice(device);
        setPrintCharacteristic(writableCharacteristic);
        setIsPrinterConnected(true);
        alert("Printer reconnected successfully!");
      } else {
        alert("Failed to reconnect writable characteristic.");
      }
    } catch (error) {
      alert("Failed to reconnect to the printer: " + error.message);
    }
  };

  const testPrint = async () => {
    if (!printCharacteristic) {
      alert("No writable characteristic found! Please reconnect.");
      return;
    }

    try {
      const encoder = new TextEncoder();
      const printCommand = encoder.encode("Hello, World!\n\n");
      const cutCommand = new Uint8Array([0x1d, 0x56, 0x00]); // Cut paper command

      await printCharacteristic.writeValue(printCommand);
      await printCharacteristic.writeValue(cutCommand);

      alert("Test print sent!");
    } catch (error) {
      alert("Failed to send print command: " + error.message);
    }
  };

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <h1>Bluetooth Printer Test</h1>
      <button
        onClick={searchPrinter}
        disabled={isPrinterConnected}
        style={{ margin: "10px", padding: "10px 20px" }}
      >
        {isPrinterConnected ? "Printer Connected" : "Search Printer"}
      </button>
      <button
        onClick={reconnectPrinter}
        style={{ margin: "10px", padding: "10px 20px" }}
      >
        Reconnect Printer
      </button>
      <button
        onClick={testPrint}
        disabled={!isPrinterConnected}
        style={{ margin: "10px", padding: "10px 20px" }}
      >
        Test Print
      </button>

      {deviceName && (
        <div>
          <h3>Printer Info</h3>
          <p>Device: {deviceName}</p>
          <p>Characteristic: {characteristicUUID}</p>
        </div>
      )}
    </div>
  );
};

export default BluetoothPrinter;

import React, { useState, useEffect } from "react";

const BluetoothPrinter = () => {
  const [printerDevice, setPrinterDevice] = useState(null);
  const [printCharacteristic, setPrintCharacteristic] = useState(null);
  const [deviceName, setDeviceName] = useState("");
  const [characteristicUUID, setCharacteristicUUID] = useState("");
  const [isPrinterConnected, setIsPrinterConnected] = useState(false);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    const savedDeviceName = localStorage.getItem("deviceName");
    const savedCharacteristicUUID = localStorage.getItem("characteristicUUID");

    if (savedDeviceName && savedCharacteristicUUID) {
      setDeviceName(savedDeviceName);
      setCharacteristicUUID(savedCharacteristicUUID);
      setIsPrinterConnected(true);
    }
  }, []);

  // Open Bluetooth device selection dialog and connect to a printer
  const searchPrinter = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: ["00001800-0000-1000-8000-00805f9b34fb"],
      });

      const server = await device.gatt.connect();
      console.log("Connected to printer:", device.name);

      const writableCharacteristic = await findWritableCharacteristic(server);

      if (writableCharacteristic) {
        savePrinterDetails(device.name, writableCharacteristic.uuid);
        setPrinterDevice(device);
        setPrintCharacteristic(writableCharacteristic);
        setIsPrinterConnected(true);

        alert(`Connected to printer: ${device.name}`);
      } else {
        alert("No writable characteristic found! Please connect the printer.");
        openBluetoothList();
      }
    } catch (error) {
      alert("Failed to connect to printer: " + error.message);
    }
  };

  // Helper: Find a writable characteristic
  const findWritableCharacteristic = async (server) => {
    const services = await server.getPrimaryServices();
    for (let service of services) {
      const characteristics = await service.getCharacteristics();
      for (let characteristic of characteristics) {
        if (characteristic.properties.write) {
          console.log("Writable characteristic found:", characteristic.uuid);
          return characteristic;
        }
      }
    }
    return null;
  };

  // Open Bluetooth list for device selection
  const openBluetoothList = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: ["00001800-0000-1000-8000-00805f9b34fb"],
      });

      const server = await device.gatt.connect();
      const writableCharacteristic = await findWritableCharacteristic(server);

      if (writableCharacteristic) {
        savePrinterDetails(device.name, writableCharacteristic.uuid);
        setPrinterDevice(device);
        setPrintCharacteristic(writableCharacteristic);
        setIsPrinterConnected(true);

        alert(`Connected to printer: ${device.name}`);
      } else {
        alert("Writable characteristic not found! Try again.");
      }
    } catch (error) {
      alert("Failed to open Bluetooth list: " + error.message);
    }
  };

  // Save printer details to localStorage
  const savePrinterDetails = (deviceName, characteristicUUID) => {
    localStorage.setItem("deviceName", deviceName);
    localStorage.setItem("characteristicUUID", characteristicUUID);
    setDeviceName(deviceName);
    setCharacteristicUUID(characteristicUUID);
  };

  // Send a print command
  const printText = async () => {
    if (!printCharacteristic) {
      alert("No writable characteristic found! Please connect the printer.");
      openBluetoothList();
      return;
    }

    try {
      const encoder = new TextEncoder();
      const printCommand = encoder.encode(inputText + "\n\n");
      const cutCommand = new Uint8Array([0x1d, 0x56, 0x00]);

      await printCharacteristic.writeValue(printCommand);
      await printCharacteristic.writeValue(cutCommand);

      alert("Text printed successfully!");
    } catch (error) {
      alert("Failed to send print command: " + error.message);
    }
  };

  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <h1>Bluetooth Printer</h1>

      {isPrinterConnected ? (
        <div>
          <p>Printer: {deviceName}</p>
          <input
            type="text"
            placeholder="Enter text to print"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            style={{ margin: "10px 0", padding: "10px", fontSize: "16px", width: "300px" }}
          />
          <button
            onClick={printText}
            disabled={!inputText.trim()}
            style={{ margin: "10px", padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}
          >
            Print
          </button>
        </div>
      ) : (
        <div>
          <button
            onClick={searchPrinter}
            style={{ margin: "10px", padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}
          >
            Search for Bluetooth Printer
          </button>
        </div>
      )}
    </div>
  );
};

export default BluetoothPrinter;

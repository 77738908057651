import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails';

const LocationDishTypeDisplay = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const [items, setItems] = useState([]);
    const [DeptData, setDeptData] = useState([]);
    const [DeptDataselected, setDeptDataselected] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if(usershopid!=0){
            axios.get(`${hostlink}/${usershopid}/deptmas`) // Replace with your API endpoint
            .then(response => setDeptData(response.data))
            .catch(error => console.error('Error fetching data:', error));
        }
        
    }, [usershopid]);

    const onChangedept = (e) => {
        setDeptDataselected(e.target.value);
    }

    const handlefindItem = () => {
        if (DeptDataselected === 0) {
            toast.error("Please Select Department...");
            document.getElementById("DeptDataselected").focus();
            return false;
        }
        console.log(`${hostlink}/api/executeQuery?sqlQuery=select dishtypemas.id,dishtypemas.dtname,(select srnoid from dishtypelocation where shopid = ${usershopid} and deptcode=${DeptDataselected} and dtid= dishtypemas.id) as srnoid,(select status from dishtypelocation where shopid = ${usershopid} and deptcode=${DeptDataselected} and dtid= dishtypemas.id) as status from dishtypemas where shopid = ${usershopid}`)
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select dishtypemas.id,dishtypemas.dtname,(select srnoid from dishtypelocation where shopid = ${usershopid} and deptcode=${DeptDataselected} and dtid= dishtypemas.id) as srnoid,(select status from dishtypelocation where shopid = ${usershopid} and deptcode=${DeptDataselected} and dtid= dishtypemas.id) as status from dishtypemas where shopid = ${usershopid}`)
            .then(response => {
                const fetchedItems = response.data;
                setItems(fetchedItems);
                
                // Update selectedItems based on item status
                const initialSelectedItems = fetchedItems
                    .filter(item => item[3] === 1) // Adjust index based on actual status location
                    .map(item => item[0]); // Get item IDs
                setSelectedItems(initialSelectedItems);
                setSelectAll(initialSelectedItems.length === fetchedItems.length); // Set "Select All" state
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems(prevSelectedItems =>
            prevSelectedItems.includes(itemId)
                ? prevSelectedItems.filter(id => id !== itemId)
                : [...prevSelectedItems, itemId]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(items.map(item => item[0])); // Assuming each item has a unique 'id'
        }
        setSelectAll(!selectAll);
    };

    const handleSave = async () => {
        const savedData = items.map(item => ({
            dtid: item[0],        // Assuming item[0] is itemid
            srnoid: item[2],        // Assuming item[2] is srnoid
            status: selectedItems.includes(item[0]) ? 1 : 0,  // Set status to 1 if selected, 0 if not
            deptcode: DeptDataselected,
            shopid: usershopid
        }));
        
        try {
            await axios.post(`${hostlink}/locDTM`, savedData);
            toast.success("Save Successful");
            setTimeout(() => {
                window.location.assign("/LocationDishTypeDisplay");
            }, 1000);
        } catch {
            toast.error("Save failed.");
        }
    };

    const handleCancel = () => {
        setSelectedItems([]);
        setSelectAll(false);
    };

    return (
        <div className="container mt-4">
            <h5 style={{color:"blue"}}><u>Store Main-Cat Display</u></h5>
            <div className='row'>
                <div className="col-md-3"><h3>Select </h3></div>
                <div className="col-md-2 text-end"><h5>Department</h5></div>
                <div className="col-md-2">
                    <select id="DeptDataselected" onChange={onChangedept} name='DeptDataselected' value={DeptDataselected} className="form-select">
                        <option value={0}>Not Selected</option>
                        {DeptData.map(x => (
                            <option key={x.id} value={x.id}>{x.deptname}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2 text-start">
                    <button onClick={handlefindItem} className='btn btn-outline-dark'>Load Data</button>
                </div>
            </div>
            <div className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectAll"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
                <label className="form-check-label" htmlFor="selectAll">
                    Select All
                </label>
            </div>

            <ul className="list-group my-3">
                {items.map(item => (
                    <li key={item[0]} className="list-group-item">
                        <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={selectedItems.includes(item[0])}
                            onChange={() => handleCheckboxChange(item[0])}
                        />
                        {item[1]}
                    </li>
                ))}
            </ul>

            <div className="d-flex justify-content-end">
                <button className="btn btn-primary me-2" onClick={handleSave}>
                    Save
                </button>
                <Link to={"/"} className="btn btn-secondary" onClick={handleCancel}>
                    Cancel
                </Link>
            </div>

            <ToastContainer />
        </div>
    );
};

export default LocationDishTypeDisplay;

import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import numberToWords from 'number-to-words';
import { UserLoginDetails } from '../Hostlink/UserLoginDetails'
import './MessageBox.css'; // 
const RestBillView = () => {
    const { appState, userLoginDetails, updateAppState, userType } = useContext(UserLoginDetails);
    const usershopid = appState?.[0]?.id || 0;
    const rs_PrintLogo = appState?.[0]?.printlogo || 0;
    const usershopiditemprintname = appState?.[0]?.otheritemprintname || 0;
    const IS_SHOW_GST_SUMMARY = appState?.[0]?.showtaxsummary || 0;
    const fs_cname = appState?.[0]?.fs_cname || 0;
    const [isBluetoothConnected, setIsBluetoothConnected] = useState(false);
    const [printerDevice, setPrinterDevice] = useState(null);
    const [printCharacteristic, setPrintCharacteristic] = useState(null);
    const [deviceName, setDeviceName] = useState("");
    const [characteristicUUID, setCharacteristicUUID] = useState("");
    const [isPrinterConnected, setIsPrinterConnected] = useState(false);
    const checkBluetoothConnectionOnPrint = async () => {
        return new Promise(async (resolve, reject) => {
            if (printCharacteristic) {
                resolve(true); // Bluetooth is already connected
            } else {
                try {
                    // Open Bluetooth list if not connected
                    await openBluetoothList(); // Wait for Bluetooth list to open

                    const interval = setInterval(() => {
                        if (printCharacteristic) {
                            clearInterval(interval); // Stop checking once connected
                            resolve(true); // Bluetooth is now connected
                        }
                    }, 1000); // Check every second
                } catch (error) {
                    console.error("Error during Bluetooth connection:", error);
                    reject(false); // Reject the promise if something goes wrong
                }
            }
        });
    };


    // Helper: Find a writable characteristic
    const findWritableCharacteristic = async (server) => {
        const services = await server.getPrimaryServices();
        for (let service of services) {
            const characteristics = await service.getCharacteristics();
            for (let characteristic of characteristics) {
                if (characteristic.properties.write) {
                    setIsBluetoothConnected(true);
                    toast.success("Bluetooth Connected")
                    console.log("Writable characteristic found:", characteristic.uuid);
                    return characteristic;
                }
            }
        }
        return null;
    };

    // Open Bluetooth list for device selection
    const openBluetoothList = async () => {
        try {
            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: true,
                optionalServices: ["00001800-0000-1000-8000-00805f9b34fb"],
            });

            const server = await device.gatt.connect();
            const writableCharacteristic = await findWritableCharacteristic(server);

            if (writableCharacteristic) {
                savePrinterDetails(device.name, writableCharacteristic.uuid);
                setPrinterDevice(device);
                setPrintCharacteristic(writableCharacteristic);
                setIsPrinterConnected(true);

                // alert(`Connected to printer: ${device.name}`);
            } else {
                alert("Writable characteristic not found! Try again.");
            }
        } catch (error) {
            alert("Failed to open Bluetooth list: " + error.message);
        }
    };

    // Save printer details to localStorage
    const savePrinterDetails = (deviceName, characteristicUUID) => {
        localStorage.setItem("deviceName", deviceName);
        localStorage.setItem("characteristicUUID", characteristicUUID);
        setDeviceName(deviceName);
        setCharacteristicUUID(characteristicUUID);
    };

    const printTextUSBBill = async (data) => {
        console.log(data)
        console.log(`${hostlink}/printBill/${Number(appState?.[0]?.printertypesize || 48)}/${encodeURIComponent(appState?.[0]?.printername)}`)

        return false;
        try {
            const response = await fetch(`${hostlink}/printBill/${Number(appState?.[0]?.printertypesize || 48)}/${encodeURIComponent(appState?.[0]?.printername)}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                console.log('Print command sent successfully');
            } else {
                console.error('Failed to send print command:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending print command:', error);
        }
    };
    const printdata = async (e) => {
        try {
            console.log(`${hostlink}/RestbillviewOne/${usershopid}/${e}`)
            const response = await axios.get(`${hostlink}/RestbillviewOne/${usershopid}/${e}`);
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);

                const data = `1,${hostlink}/RestbillviewOne/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 80mm;
                            margin: 0; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 80mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    const printdataPrint = async (e) => {
        try {
            if (appState?.[0]?.printertype == 1) {
                if (!isBluetoothConnected) {
                    await checkBluetoothConnectionOnPrint();
                    return false
                }
            }

            const response = await axios.get(`${hostlink}/RestbillviewOne/${usershopid}/${e}`);
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);

                const data = `1,${hostlink}/RestbillviewOne/${usershopid}/${e},${printContent}`;
                // console.log(data)
                if (appState?.[0]?.printertype == 1) {
                    // Bluetooth printer is selected, print using Bluetooth
                    await printTextBillBluetooth(response.data);
                    return false;
                } else if (appState?.[0]?.printertype == 2) {
                    // Print using WebView
                    await printTextUSBBill(response.data);
                    return false;
                } else if (window.ReactNativeWebView) {
                    // Print using WebView
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }

                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();

                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 80mm;
                            margin: 0; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 80mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);

                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    const printLongText = async (text, maxLength = 20) => {
        const setLineHeight = new Uint8Array([0x1B, 0x33, 0x32]);
        const encoder = new TextEncoder();
        let textToPrint = text;
        let currentText = textToPrint.slice(0, maxLength);  // Extract first chunk
    
        // Keep sending text in chunks of `maxLength` (20 characters)
        await printCharacteristic.writeValue(setLineHeight);
        while (currentText) {
            await printCharacteristic.writeValue(encoder.encode(currentText));  // Send chunk to printer
            textToPrint = textToPrint.slice(maxLength);  // Remove the printed chunk
            currentText = textToPrint.slice(0, maxLength);  // Get the next chunk
        }
    };
    
    
    
    
    const printTextBillBluetooth = async (data) => {

        // Collecting data and formatting
        const formattedKotNo = `Bill No: ${data[0].SaleMasDTO.shopvno}`;
        const tableInfo = data.length > 0 && data[0].tablename !== null ? `Table : ${data[0].tablename}` : "";
        const formattedTime = `Time : ${data[0].SaleMasDTO.entrytime}`;
        const formattedDate = `Bill Date : ${data[0].SaleMasDTO.bldate ? new Date(data[0].SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}`;
        const formattedNOP = data.length > 0 && data[0]?.SaleMasDTO?.nop !== null ? `NOP : ${data[0]?.SaleMasDTO?.nop}` : "";
        const formattedWaiter = data.length > 0 && data[0]?.SaleMasDTO?.wname !== null ? `Waiter : ${data[0]?.SaleMasDTO?.wname}` : "Waiter : NA";

        const billprintline1 = appState?.[0]?.billprintline1 || ""; const billprintline1Html1 = billprintline1 !== null ? `${billprintline1}` : '';
        const billprintline2 = appState?.[0]?.billprintline2 || ""; const billprintline1Html2 = billprintline2 !== null ? `${billprintline2}` : '';
        const billprintline3 = appState?.[0]?.billprintline3 || ""; const billprintline1Html3 = billprintline3 !== null ? `${billprintline3}` : '';
        const billprintline4 = appState?.[0]?.billprintline4 || ""; const billprintline1Html4 = billprintline4 !== null ? `${billprintline4}` : '';
        const billprintline5 = appState?.[0]?.billprintline5 || ""; const billprintline1Html5 = billprintline5 !== null ? `${billprintline5}` : '';

        const billprintlineTC1 = appState?.[0]?.billprintlineTC1 || ""; const Html_billprintlineTC1 = billprintlineTC1 !== null ? `${billprintlineTC1}` : '';
        const billprintlineTC2 = appState?.[0]?.billprintlineTC2 || ""; const Html_billprintlineTC2 = billprintlineTC2 !== null ? `${billprintlineTC2}` : '';
        const billprintlineTC3 = appState?.[0]?.billprintlineTC3 || ""; const Html_billprintlineTC3 = billprintlineTC3 !== null ? `${billprintlineTC3}` : '';
        const billprintlineTC4 = appState?.[0]?.billprintlineTC4 || ""; const Html_billprintlineTC4 = billprintlineTC4 !== null ? `${billprintlineTC4}` : '';
        const billprintlineTC5 = appState?.[0]?.billprintlineTC5 || ""; const Html_billprintlineTC5 = billprintlineTC5 !== null ? `${billprintlineTC5}` : '';
        // Header labels for the item table
        const formattedSRNO = "SN";
        const formattedITEMDETAIL = "Item Name";
        const formattedQTY = "Qty";
        const formattedRATE = "Rate";
        const formattedAMOUNT = "Amount";

        const TotalFooter = "Total :";
        const totalQty = data.reduce((total, resp) => total + parseFloat(resp.SaleMasDTO.qty), 0).toFixed(2);
        const totalAmount = Number(data.reduce((total, resp) => total + parseFloat(resp.SaleMasDTO.qty * resp.SaleMasDTO.rate), 0)).toFixed(2);
        if (!printCharacteristic) {
            alert("No writable characteristic found! Please connect the printer.");
            openBluetoothList();
            return;
        }
        try {
            const encoder = new TextEncoder();
            const MAX_LINE_WIDTH = 48;  //  3 inch 48, 2 inch 32
            const dashedLine = '------------------------------------------------';  // Create dashed line for separator
            const DATE_TIME = `${formattedDate.padEnd(24, ' ')}${formattedTime.padStart(24, ' ')}`;
            const WAITER_NOP = `${formattedWaiter.padEnd(MAX_LINE_WIDTH / 2, ' ')}${formattedNOP.padStart(MAX_LINE_WIDTH / 2, ' ')}`;
            const THEADER = `${formattedSRNO.padEnd(3, ' ')}${formattedITEMDETAIL.padEnd(MAX_LINE_WIDTH - 3 - 8 - 8 - 8, ' ')}${formattedQTY.padStart(8, ' ')}${formattedRATE.padStart(8, ' ')}${formattedAMOUNT.padStart(8, ' ')}`;
            const TOTAL_FOOTER = `${'Total'.padEnd(3, ' ')}${' '.padEnd(MAX_LINE_WIDTH - 5 - 8 - 8 - 8, ' ')}${totalQty.padStart(8, ' ')}${' '.padEnd(8, ' ')}${totalAmount.padStart(8, ' ')}`;
            const TOTAL_1 = `${'Total Taxable Amount :'.padEnd(MAX_LINE_WIDTH - 13 - 8, ' ')}${data.reduce((total, resp) => total + Number(resp.SaleMasDTO.taxableamt), 0).toFixed(2).padStart(8, ' ')}`;
            const TOTAL_CGST = `${'CGST Amount :'.padEnd(MAX_LINE_WIDTH - 22 - 8, ' ')}${data.reduce((total, resp) => Number(resp.SaleMasDTO.totgst / 2), 0).toFixed(2).padStart(8, ' ')}`;
            const TOTAL_SGST = `${'SGST Amount :'.padEnd(MAX_LINE_WIDTH - 22 - 8, ' ')}${data.reduce((total, resp) => Number(resp.SaleMasDTO.totgst / 2), 0).toFixed(2).padStart(8, ' ')}`;
            const TOTAL_CESS = `${'CESS Amount :'.padEnd(MAX_LINE_WIDTH - 22 - 8, ' ')}${data.reduce((total, resp) => total + Number(resp.SaleMasDTO.cessamt), 0).toFixed(2).padStart(8, ' ')}`;
            const TOTAL_BillAMT = `${'Bill Amount :'.padEnd(MAX_LINE_WIDTH - 13 - 8, ' ')}${data.reduce((total, resp) => Number(resp.SaleMasDTO.totblamt), 0).toFixed(2).padStart(8, ' ')}`;
            const DeliveryCustomer =
                `Customer details\r\n` +
                `-----------------\r\n` +
                `Name   : ${data?.[0]?.SaleMasDTO?.custname || ''}\r\n` +
                `Mob No : ${data?.[0]?.SaleMasDTO?.custmob || ''}\r\n` +
                `Add    : ${data?.[0]?.SaleMasDTO?.custadd1 || ''}\r\n` +
                `GST No : ${data?.[0]?.SaleMasDTO?.custgstno || ''}`;
            const custtPay1 = data.length > 0 ? data[0].SaleMasDTO.pay1code : null;
            const custdlvdetailHtmlcusttPay1 = custtPay1 !== null && custtPay1 !== 0 ? `${data.length > 0 ? data[0].Pay1Name : ''} : ${data.length > 0 ? data[0].SaleMasDTO.pay1amt : ''}` : '';

            const custtPay2 = data.length > 0 ? data[0].SaleMasDTO.pay2code : null;
            const custdlvdetailHtmlcusttPay2 = custtPay2 !== null && custtPay2 !== 0 ? `${data.length > 0 ? data[0].Pay2Name : ''} : ${data.length > 0 ? data[0].SaleMasDTO.pay2amt : ''}` : '';

            const TipAmount = data.length > 0 ? data[0].SaleMasDTO.tipamount : null;
            const custdlvdetailHtmlTipAmount = TipAmount !== null && TipAmount !== 0 ? `Tip Amount : ${data.length > 0 ? data[0].SaleMasDTO.tipamount : ''}` : '';

            const custtndr = data.length > 0 ? data[0].SaleMasDTO.tenderamt : null;
            const custdlvdetailHtmlTndr = custtndr !== null && custtndr !== 0 ? `Tender Amount : ${data.length > 0 ? data[0].SaleMasDTO.tenderamt : ''} +"\r\n" Returnable Amount : ${data.length > 0 ? data[0].SaleMasDTO.returnamt : ''}` : '';

            // ESC/POS Commands for formatting
            const alignCenter = new Uint8Array([0x1b, 0x61, 0x01]); // Align center
            const alignLeft = new Uint8Array([0x1b, 0x61, 0x00]); // Align left
            const alignRight = new Uint8Array([0x1b, 0x61, 0x02]);
            const boldOn = new Uint8Array([0x1b, 0x45, 0x01]); // Bold ON
            const boldOff = new Uint8Array([0x1b, 0x45, 0x00]); // Bold OFF
            const doubleSize = new Uint8Array([0x1d, 0x21, 0x11]); // Double height & width
            const normalSize = new Uint8Array([0x1d, 0x21, 0x00]); // Normal size
            const resetPrinter = new Uint8Array([0x1b, 0x40]);
            // const lineBreak = new Uint8Array([0x0D, 0x0A]); // CR + LF
            const lineBreak = new Uint8Array([0x0A]); 
            await printCharacteristic.writeValue(resetPrinter);
            await printCharacteristic.writeValue(alignCenter);
            await printCharacteristic.writeValue(boldOn);
            await printCharacteristic.writeValue(doubleSize);
            await printLongText(`${appState?.[0]?.cname || '-'}`);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(normalSize);
            await printCharacteristic.writeValue(boldOff);
            await printLongText(billprintline1);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(billprintline2);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(billprintline3);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignLeft);
            await printCharacteristic.writeValue(boldOn);
            await printLongText(`Bill No : ${data[0].SaleMasDTO.shopvno || '-'}`);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(DATE_TIME);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(boldOff);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(DeliveryCustomer);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(THEADER);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignLeft);
            const MAX_ITEM_NAME_LENGTH = MAX_LINE_WIDTH - 3 - 8 - 8 - 8; // Adjust this based on other parts of the line
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                let itemName = usershopiditemprintname === 0 ? item.ItemMaster.itname : item.ItemMaster.ItemPrintname;
                if (itemName.length > MAX_ITEM_NAME_LENGTH) {
                    itemName = itemName.slice(0, MAX_ITEM_NAME_LENGTH - 3) + '...';
                }
                const formattedRow = `${(i + 1).toString().padEnd(3, ' ')}${itemName.padEnd(MAX_ITEM_NAME_LENGTH, ' ')}${item.SaleMasDTO.qty.toFixed(2).padStart(8, ' ')}${item.SaleMasDTO.rate.toFixed(2).padStart(8, ' ')}${(Number(item.SaleMasDTO.rate * item.SaleMasDTO.qty)).toFixed(2).padStart(8, ' ')}`;
                await printLongText(formattedRow);
                await printCharacteristic.writeValue(lineBreak);
                if (itemName.length > MAX_ITEM_NAME_LENGTH) {
                    const remainingName = item.ItemMaster.itname.slice(MAX_ITEM_NAME_LENGTH - 3);  // After truncation
                    const nextLine = `${"".padEnd(3, ' ')}${remainingName.padEnd(MAX_ITEM_NAME_LENGTH, ' ')}${"".padStart(8, ' ')}${"".padStart(8, ' ')}${"".padStart(8, ' ')}`;
                    await printLongText(nextLine);
                    await printCharacteristic.writeValue(lineBreak);
                }
            }
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignRight);
            await printLongText(TOTAL_FOOTER);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignRight);
            await printLongText(TOTAL_1);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(TOTAL_CGST);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(TOTAL_SGST);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(TOTAL_CESS);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(boldOn);
            await printLongText(TOTAL_BillAMT);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(boldOff);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignCenter);
            await printLongText(`In Word : ${numberToWords.toWords(Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totblamt))), 0)))}`);
            await printCharacteristic.writeValue(alignLeft);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(Html_billprintlineTC1);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(Html_billprintlineTC2);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(Html_billprintlineTC3);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(Html_billprintlineTC4);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(Html_billprintlineTC5);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignCenter);
            await printCharacteristic.writeValue(boldOn);
            await printLongText(`* THANK YOU PLEASE VISIT AGAIN *`);
            await printCharacteristic.writeValue(boldOff);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(boldOn);
            await printLongText(`PAID BY`);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(boldOff);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printCharacteristic.writeValue(alignLeft);
            await printLongText(custdlvdetailHtmlcusttPay1);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(custdlvdetailHtmlcusttPay2);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(custdlvdetailHtmlTipAmount);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(dashedLine);
            await printCharacteristic.writeValue(lineBreak);
            await printLongText(custdlvdetailHtmlTndr);
            await printCharacteristic.writeValue(lineBreak);
            const cutCommand = new Uint8Array([0x1d, 0x56, 0x00]); // Full cut
            await printCharacteristic.writeValue(cutCommand);
        } catch (error) {
            console.error("Failed to send print command:", error);

        }
    };
    const generatePrintContent = (data) => {
        let tableHTMLCESS = '';
        let tableHTML = '';
        if (IS_SHOW_GST_SUMMARY === 1) {
            const groupedData = {};
            // Group data by GST rate
            data.forEach(entry => {
                const gstRate = entry.SaleMasDTO.gst.toString().padStart(2, '0');
                if (!groupedData[gstRate]) {
                    groupedData[gstRate] = [];
                }
                groupedData[gstRate].push(entry);
            });

            // Extract GST rates from the groupedData keys, convert them to numbers, and sort them
            const sortedGST = Object.keys(groupedData).map(Number).sort((a, b) => a - b);

            if (Object.keys(sortedGST).length > 0) {
                tableHTML = '<table style="width: 2.7in;"><tr style="vertical-align: middle;"><td style="width: 33%; text-align: center;"></td><td style="width: 34%; text-align: center;"><b>GST SUMMARY</b></td><td style="width: 33%; text-align: center;"></td></tr></table> <table border="1" cellspacing="0" cellpadding="0" style="width: 100%; text-align: center;"><tr><th>GST %</th><th>Taxable</th><th>CGST</th><th>SGST</th></tr>';

                // Iterate over sorted GST rates and generate table rows
                sortedGST.forEach(gstRate => {
                    const gstRateStr = gstRate.toString().padStart(2, '0');
                    const total = groupedData[gstRateStr].reduce((acc, entry) => acc + parseFloat(entry.SaleMasDTO.taxableamt), 0).toFixed(2);
                    const cgst = Number((Number(total) * gstRate / 100) / 2).toFixed(2);
                    const sgst = Number((Number(total) * gstRate / 100) / 2).toFixed(2);
                    tableHTML += `<tr><td style="width: 30%; text-align: center;">${gstRateStr}%</td><td style="width: 30%; text-align: end;">${total}&nbsp;</td><td style="width: 20%; text-align: end;">${cgst}&nbsp;</td><td style="width: 20%; text-align: end;">${sgst}&nbsp;</td></tr>`;
                });

                tableHTML += '</table>';
                // Print the HTML table
                console.log(tableHTML);
            } else {
                // Print a message indicating no CESS data found
                console.log("No GST data found.");
            }
            const groupedDatacess = {};
            data.forEach(entry => {
                const cess = entry.SaleMasDTO.cess;
                if (cess > 0) {
                    const gstRate = cess.toString().padStart(2, '0');
                    if (!groupedDatacess[gstRate]) {
                        groupedDatacess[gstRate] = [];
                    }
                    groupedDatacess[gstRate].push(entry);
                }
            });
            if (Object.keys(groupedDatacess).length > 0) {
                // Start building the HTML table
                tableHTMLCESS = '<table style="width: 2.7in;"><tr style="vertical-align: middle;"><td style="width: 10%; text-align: center;"></td><td style="width: 80%; text-align: center;"><b>CESS SUMMARY</b></td><td style="width: 10%; text-align: center;"></td></tr></table> <table border="1" cellspacing="0" cellpadding="0" style="width: 100%; text-align: center;"><tr><th>CESS %</th><th>Taxable</th><th>CESS AMT</th></tr>';

                // Iterate through CESS rates
                for (const gstRate in groupedDatacess) {
                    // Calculate total taxable amount
                    const total = groupedDatacess[gstRate].reduce((acc, entry) => acc + parseFloat(entry.SaleMasDTO.taxableamt), 0).toFixed(2);

                    // Build table rows
                    tableHTMLCESS += ` <tr><td style="width: 30%; text-align: center;">${gstRate}%</td><td style="width: 30%; text-align: end;">${total}&nbsp;</td><td style="width: 20%; text-align: end;">${Number((Number(total) * gstRate / 100)).toFixed(2)}&nbsp;</td></tr>`;
                }

                // Close the table
                tableHTMLCESS += '</table> <p style="font-size: 6px;">-</p>';

                // Print the HTML table
                console.log(tableHTMLCESS);
            } else {
                // Print a message indicating no CESS data found
                console.log("No CESS data found.");
            }

        }

        const tableInfo = data.length > 0 && data[0].tablename !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Table : ${data[0].tablename}</p1></span>` : '';

        const billprintlineTC1 = appState?.[0]?.billprintlineTC1 || 0; const Html_billprintlineTC1 = billprintlineTC1 !== null ? `<b> <p style="text-align: left; font-size: 12px; width:2.7in;">${billprintlineTC1}</p> </b>` : '';
        const billprintlineTC2 = appState?.[0]?.billprintlineTC2 || 0; const Html_billprintlineTC2 = billprintlineTC2 !== null ? `<p style="text-align: left; font-size: 10px; width:2.7in;">${billprintlineTC2}</p> ` : '';
        const billprintlineTC3 = appState?.[0]?.billprintlineTC3 || 0; const Html_billprintlineTC3 = billprintlineTC3 !== null ? `<p style="text-align: left; font-size: 10px; width:2.7in;">${billprintlineTC3}</p> ` : '';
        const billprintlineTC4 = appState?.[0]?.billprintlineTC4 || 0; const Html_billprintlineTC4 = billprintlineTC4 !== null ? `<p style="text-align: left; font-size: 10px; width:2.7in;">${billprintlineTC4}</p> ` : '';
        const billprintlineTC5 = appState?.[0]?.billprintlineTC5 || 0; const Html_billprintlineTC5 = billprintlineTC5 !== null ? `<p style="text-align: left; font-size: 10px; width:2.7in;">${billprintlineTC5}</p> ` : '';

        const billprintline1 = appState?.[0]?.billprintline1 || 0; const billprintline1Html1 = billprintline1 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline1}</p>` : '';
        const billprintline2 = appState?.[0]?.billprintline2 || 0; const billprintline1Html2 = billprintline2 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline2}</p>` : '';
        const billprintline3 = appState?.[0]?.billprintline3 || 0; const billprintline1Html3 = billprintline3 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline3}</p>` : '';
        const billprintline4 = appState?.[0]?.billprintline4 || 0; const billprintline1Html4 = billprintline4 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline4}</p>` : '';
        const billprintline5 = appState?.[0]?.billprintline5 || 0; const billprintline1Html5 = billprintline5 !== null ? `<p style="text-align: center; width:2.7in;">${billprintline5}</p>` : '';
        const Billamtinword = `<p style="text-align: center; width:2.7in;">In Word : ${numberToWords.toWords(Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totblamt))), 0)))}</p>`;
        const custdlvdetail = data.length > 0 ? data[0].SaleMasDTO.custmob : null;
        const custdlvdetailHtml = custdlvdetail !== null && custdlvdetail !== 0 ? `       <hr style="borderTop: 1px solid black; width: 2.7in;" /> <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;"><b><u>Customer Details</u></b></p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%; font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custname : ''}</p1><p1 style="text-align: end; width:50%;font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custmob : ''}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custadd1 : ''}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">${data.length > 0 ? data[0].SaleMasDTO.custgstno : ''}</p1></span>
` : '';
        const custtndr = data.length > 0 ? data[0].SaleMasDTO.tenderamt : null;
        const custdlvdetailHtmlTndr = custtndr !== null && custtndr !== 0 ? `       <hr style="borderTop: 1px solid black; width: 2.7in;" /> <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;"></p1></span>
<span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">Tender Amount : ${data.length > 0 ? data[0].SaleMasDTO.tenderamt : ''}</p1></span>
<span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">Returnable Amount : ${data.length > 0 ? data[0].SaleMasDTO.returnamt : ''}</p1></span>
` : '';


        const logoprint = data.length > 0 ? rs_PrintLogo : null;
        const logoprintHtmlTndr = logoprint !== null && logoprint !== 1 ? `<img id="shopLogo" style="display: block; margin: 0 auto; width: 250px; height: 250px; object-fit: contain;" src="./logo${usershopid}.png" alt="Logo" onerror="this.style.width='0px'; this.style.height='0px';" />` : '';

        const QRprint = data.length > 0 ? rs_PrintLogo : null;
        const QRprintHtmlTndr = QRprint !== null && QRprint !== 1 ? `<img id="shopLogo" style="display: block; margin: 0 auto; width: 200px; height: 200px; object-fit: contain;" src="./footer${usershopid}.png" alt="QR" onerror="this.style.width='0px'; this.style.height='0px';" />` : '';


        const custtPay1 = data.length > 0 ? data[0].SaleMasDTO.pay1code : null;
        const custdlvdetailHtmlcusttPay1 = custtPay1 !== null && custtPay1 !== 0 ? `       <hr style="borderTop: 1px solid black; width: 2.7in;" /> <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;"></p1></span>
<span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">${data.length > 0 ? data[0].Pay1Name : ''} : ${data.length > 0 ? data[0].SaleMasDTO.pay1amt : ''}</p1></span>
` : '';

        const custtPay2 = data.length > 0 ? data[0].SaleMasDTO.pay2code : null;
        const custdlvdetailHtmlcusttPay2 = custtPay2 !== null && custtPay2 !== 0 ? `       <hr style="borderTop: 1px solid black; width: 2.7in;" /> <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;"></p1></span>
<span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">${data.length > 0 ? data[0].Pay2Name : ''} : ${data.length > 0 ? data[0].SaleMasDTO.pay2amt : ''}</p1></span>
` : '';


        const TipAmount = data.length > 0 ? data[0].SaleMasDTO.tipamount : null;
        const custdlvdetailHtmlTipAmount = TipAmount !== null && TipAmount !== 0 ? `       <hr style="borderTop: 1px solid black; width: 2.7in;" /> <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%;"></p1></span>
<span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:100%; font-size: 14px;">Tip Amount : ${data.length > 0 ? data[0].SaleMasDTO.tipamount : ''}</p1></span>
` : '';



        const showBillDisc = data.length > 0 ? data[0].SaleMasDTO.bldiscperc : null;
        const showBillDisc_HTML = showBillDisc !== null && showBillDisc !== 0 ? `<tr style="vertical-align: middle;">
                <td style="width: 80%; text-align: end;">DISCOUNT ON BILL ${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldiscperc))), 0))}% :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.bldiscamt))), 0)).toFixed(2)}</td></tr>
            ` : '';

        const showTOTALCGST = data.length > 0 ? data[0].SaleMasDTO.totgst : null;
        const showTOTALCGST_Html = showTOTALCGST !== null && showTOTALCGST !== 0 ? `<tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">TOTAL CGST :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totgst / 2))), 0)).toFixed(2)}</td></tr>
    ` : '';

        const showTOTALSGST = data.length > 0 ? data[0].SaleMasDTO.totgst : null;
        const showTOTALSGST_Html = showTOTALSGST !== null && showTOTALSGST !== 0 ? `<tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">TOTAL SGST :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totgst / 2))), 0)).toFixed(2)}</td></tr>
    ` : '';

        const showTOTALCESS = data.length > 0 ? data[0].SaleMasDTO.cessamt : null;
        const showTOTALCESS_Html = showTOTALCESS !== null && showTOTALCESS !== 0 ? `<tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">TOTAL CESS :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.cessamt))), 0)).toFixed(2)}</td></tr>
    ` : '';

        const ROUNDOFF = data.length > 0 ? data[0].SaleMasDTO.roundoff : null;
        const ROUNDOFF_Html = ROUNDOFF !== null && ROUNDOFF !== 0 ? `<tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">ROUND OFF :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.roundoff))), 0)).toFixed(2)}</td></tr>
    ` : '';

        const showBillservCH = data.length > 0 ? data[0].SaleMasDTO.servicechperc : null;
        const showBillservCH_HTML = showBillservCH !== null && showBillservCH !== 0 ? `            <tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">SERVICE CHARGE ${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.servicechperc))), 0))}% :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.servicechamt))), 0)).toFixed(2)}</td></tr>
            ` : '';

        const showBilldlvCH = data.length > 0 ? data[0].SaleMasDTO.bldlvchperc : null;
        const showBilldlvCH_HTML = showBilldlvCH !== null && showBilldlvCH !== 0 ? `            <tr style="vertical-align: middle;">
        <td style="width: 80%; text-align: end;">DELIVERY CHARGE ${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldlvchperc))), 0))}% :</td>
        <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldlvchamt))), 0)).toFixed(2)}</td>    </tr>
            ` : '';

        const showBilldlvCHAmt = data.length > 0 ? data[0].SaleMasDTO.bldlvchamount : null;
        const showBilldlvCHAmt_HTML = showBilldlvCHAmt !== null && showBilldlvCHAmt !== 0 ? `            <tr style="vertical-align: middle;">
            <td style="width: 80%; text-align: end;">DELIVERY CHARGE :</td>
            <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.bldlvchamount))), 0)).toFixed(2)}</td></tr>
            ` : '';

        // ${Number((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))+(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.gst / 100) +(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.cess / 100)).toFixed(2)}
        //${Number(data.reduce((total, x) => total + parseFloat(Number((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))+(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.gst / 100) +(((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - (((((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) + (((x.SaleMasDTO.rate * x.SaleMasDTO.qty) - ((x.SaleMasDTO.rate * x.SaleMasDTO.qty) * x.SaleMasDTO.discperc / 100)) * Number(x.SaleMasDTO.bldiscperc) / 100)))))) * x.SaleMasDTO.cess / 100)).toFixed(2)), 0)).toFixed(2)}
        const showFlatDiscount = data.length > 0 ? data[0].SaleMasDTO.flatdiscount : null;
        const showFlatDiscount_HTML = showFlatDiscount !== null && showFlatDiscount !== 0 ? `            <tr style="vertical-align: middle;">
            <td style="width: 80%; text-align: end;">FLAT DISCOUNT :</td>
            <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.flatdiscount))), 0)).toFixed(2)}</td></tr>
            ` : '';


        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;overflow-x: hidden;}div{width: 2.7in;}hr{width: 2.7in;} .advertisement-text {position: absolute;font-size: small;white-space: nowrap;transform-origin: right;color: #ffffff;left: -12mm;top:0;transform: rotate(-90deg);}</style><div style="font-family: 'Bahnschrift Condensed'; width: 2.7in;"><p1 style="display: block; margin: 0 auto;text-align: center;">${data.length > 0 ? data[0].bltype : ''}</p1>
        ${logoprintHtmlTndr}        
        <p class="advertisement-text">MYPCKART</p>
            <h1 style="text-align: center;font-Size:${fs_cname}px;">${appState?.[0]?.cname || '-'}</h1>
        ${billprintline1Html1}        ${billprintline1Html2}        ${billprintline1Html3}        ${billprintline1Html4}        ${billprintline1Html5}
        <hr style="borderTop: 1px solid black; width: 2.7in;" />
        <b><span style="display:flex;"><p1 style="text-align: start; width:50%;">Bill No : ${data.length > 0 ? data[0].SaleMasDTO.shopvno : ''}
        </p1>${tableInfo}</p1></span><span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%;">Bill Date : ${data[0].SaleMasDTO.bldate ? new Date(data[0].SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;">Bill Time : ${data[0].SaleMasDTO.entrytime}</p1></span>
        <span style="display:flex; width: 2.7in;"><p1 style="text-align: start; width:50%;">Waiter : ${data[0].SaleMasDTO.wname ? data[0].SaleMasDTO.wname : "NA"}</p1><p1 style="text-align: end; width:50%;"> NOP : ${data[0].SaleMasDTO.nop ? data[0].SaleMasDTO.nop : "NA"}</p1></span>
        </b>
        
        ${custdlvdetailHtml}
        <table style="border-Top: 1px solid black; width: 2.7in;">
        <tr style="border-bottom: 1px solid black; width: 100%;">
            <td style="width: 10%;border-bottom: 1px solid black;">SN</td>
            <td style="width: 40%;border-bottom: 1px solid black;">Item Name</td>
            <td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td>
            <td style="width: 20%;border-bottom: 1px solid black; text-align: end;">Rate</td>
            <td style="width: 20%;border-bottom: 1px solid black; text-align: end;">Amount</td>
        </tr>
        ${data.map((x, index) =>
            x.SaleMasDTO.qty !== 0 ?
                `<tr style="width: 100%">
                  <td style="width: 10%">${index + 1}</td>
                  <td style="width: 40%; max-width: 15ch; word-wrap: break-word;">
                    ${usershopiditemprintname === 0 ? (
                    x.ItemMaster.itname
                ) : (
                    x.ItemMaster.itnameprint
                )} <br> ${x.SaleMasDTO.discperc !== 0 ? `Discount: ${x.SaleMasDTO.discperc}%` : ''}
                  </td>
                  <td style="width: 10%; text-align: center;">${x.SaleMasDTO.qty}</td>
                  <td style="width: 20%; text-align: end;">${x.SaleMasDTO.rate.toFixed(2)}</td>
                  <td style="width: 20%; text-align: end;">${Number(x.SaleMasDTO.rate * x.SaleMasDTO.qty).toFixed(2)}</td>
              </tr>`
                : ''
        ).join('')}
          

          
          
          <tr style="border-top: 1px solid black;vertical-align: middle;">
            <td style="width: 10%;border-top: 1px solid black; border-bottom: 1px solid black; "></td>
            <td style="width: 30%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: start; vertical-align: middle;"><b>Total :</b></td>
            <td style="width: 10%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: end;"><b>${Number(data.reduce((total, resp) => total + parseFloat(resp.SaleMasDTO.qty), 0)).toFixed(2)}</b></td>
            <td style="width: 20%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: end;"></td>
            <td style="width: 10%;border-top: 1px solid black; border-bottom: 1px solid black; text-align: end;"><b>${Number(data.reduce((total, resp) => total + parseFloat(resp.SaleMasDTO.qty * resp.SaleMasDTO.rate), 0)).toFixed(2)}</b></td>
          </tr>
          </table>

         
          
          <table style="width: 2.7in;">
                ${showBillDisc_HTML}
            <tr style="vertical-align: middle;">
                <td style="width: 80%; text-align: end;">TOTAL TAXABLE AMOUNT :</td>
                <td style="width: 20%; text-align: end;">${Number(data.reduce((total, resp) => total + parseFloat((Number(resp.SaleMasDTO.taxableamt))), 0)).toFixed(2)}</td>
            </tr>
            
            
            ${showTOTALCGST_Html}
            ${showTOTALSGST_Html}
            ${showTOTALCESS_Html}

            
            ${showBillservCH_HTML}
            ${showBilldlvCH_HTML}
            ${showBilldlvCHAmt_HTML}
            ${showFlatDiscount_HTML}

            ${ROUNDOFF_Html}
            <tr style="">
                <td style="width: 80%; text-align: end;font-size: 20px;" class="bill-amount-value"><b>BILL AMOUNT :</b></td>
                <td style="width: 20%; text-align: end;font-size: 20px;"><b>${Number(data.reduce((total, resp) => parseFloat((Number(resp.SaleMasDTO.totblamt))), 0)).toFixed(2)}</b></td>
            </tr>

            </table>
            <hr style="border: 0.1px solid black; width: 2.7in;transform: scaleY(0.5);">
            ${Billamtinword}
            ${tableHTML}
          
            ${tableHTMLCESS}
            <hr style="border: 0.1px solid black; width: 2.7in;transform: scaleY(0.5);">
            ${Html_billprintlineTC1}
            ${Html_billprintlineTC2}
            ${Html_billprintlineTC3}
            ${Html_billprintlineTC4}
            ${Html_billprintlineTC5}

            <hr style="border: 0.1px solid black; width: 2.7in;transform: scaleY(0.5);">
            <b>
            <span style="display:flex; width: 2.7in;"><p1 style="text-align: center; width:100%; font-size: 14px;">*** THANK YOU PLEASE VISIT AGAIN ***</p1></span>
            </b>
            <hr style="border: 0.1px solid black; width: 2.7in;transform: scaleY(0.5);">
            <h3 style="text-align: center;">PAID BY</h3>
            ${custdlvdetailHtmlcusttPay1}
            ${custdlvdetailHtmlcusttPay2}
            ${custdlvdetailHtmlTipAmount}
            <hr style="border: 0.1px solid black; width: 2.7in;transform: scaleY(0.5);">
            ${custdlvdetailHtmlTndr}
            <hr style="border: 0.1px solid black; width: 2.7in;transform: scaleY(0.5);">
            <h6>.</h6>
            ${QRprintHtmlTndr}
        </div>`;
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        statusupdate: ""
    })




    const [AccCode, setAccCode] = useState([])
    const { deleteid, deletemsg, statusupdate } = messagedata;
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [blno, setblno] = useState(0)
    const [isModalOpenCancel, setIsModalOpenCancel] = useState(false);
    const handleOpenModal = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint, statusupdate: 1 }); setIsModalOpen(true); };
    const handleOpenModaledit = (e) => { setblno(e); setIsModalOpenEdit(true); };
    const handleOpenModalCancel = (e, blnoprint) => { setblno(e); setMessagedata({ ...messagedata, deleteid: e, deletemsg: blnoprint, statusupdate: 2 }); setIsModalOpenCancel(true); };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleCloseModalCancel = () => { setIsModalOpenCancel(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleNoClickCancel = () => { console.log('User clicked No'); handleCloseModalCancel(); };
    const handleCloseModalEdit = () => { setIsModalOpenEdit(false); window.location.assign("/SaleBill") };
    const handleNoClickEdit = () => { console.log('User clicked No'); handleCloseModalEdit(); };
    var billgst;

    var billgst;
    const [logroom, setLogroom] = useState();
    const [GST_P_1, setGST_P_1] = useState(0);
    const [GST_P_2, setGST_P_2] = useState(0);
    const [GST_P_3, setGST_P_3] = useState(0);
    const [GST_P_4, setGST_P_4] = useState(0);

    let bl_Tot_1 = 0;
    let bl_RoomRent = 0;
    let bl_Disc = 0;
    let bl_total = 0;
    let bl_gstTotal = 0;
    let bl_blamt = 0;

    const [roomdetail, setroomdetail] = useState([]);
    const today = new Date();
    const [refreshData, setRefreshData] = useState(false)
    const [cancelrem, setcancelrem] = useState("")
    const api = "/bill";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const [roomrentcalculation, setroomrentcalculation] = useState([]);
    const [newItemdata, setItemdata] = useState({ chdate: '', rtype: 0, roomtypeview: '', roomnoview: '', rcode: 0, rent: 0, exbed: 0, exbedch: 0, chid: 0 });
    const Closeform = () => {
        window.location.assign("/SaleBill")
    }
    const [viewbldate, setBldate] = useState({
        blfromdate: today.toISOString().split('T')[0],
        bltodate: today.toISOString().split('T')[0]
    })
    const { blfromdate, bltodate } = viewbldate;
    const bldatechange = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        setBldate({ ...viewbldate, [name]: value })
        setRefreshData(true)
    }
    const [logdetail, setlogdetail] = useState({
        onform: "SALE",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })

    const handleCheckout = async (e, id, rnamedata) => {

        await updateStatus(id); // Pass both the event object and the ID to updateStatus
        handleOpenModaledit(rnamedata)
        // billtotal(); // Call billtotal after updating status


    };
    const getrent = (e) => {
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {

                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                setSavedata(ld);
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e) => {

        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        } else {
            console.log(`${hostlink}/${usershopid}/RestbillviewNew/0/${blfromdate}/${bltodate}/${searchValue}`)
            axios.get(`${hostlink}/${usershopid}/RestbillviewNew/0/${blfromdate}/${bltodate}/${searchValue}`)
                .then((Response) => {
                    console.log("Data Fatched")
                    setData(Response.data)
                })
                .catch((Response) => {
                    console.log("Data Not Fatched")
                })
        }
    }

    const saveBill = async (e) => {
        const rnameString = roomdetail.map(item => item.roomnoview).join(',');
        const rcodeString = roomdetail.map(item => item.rcode).join(',');

        var ld = { ...billdata, roomdescription: rnameString, roomdescriptioncode: rcodeString, narration: usershopid + document.getElementById("checkoutdate").value.trim() + document.getElementById("inputname").value.trim() }
        await axios.put(`${hostlink}/bill`, ld)
            .then((respbill) => {
                var billno;
                billno = (respbill.data)
                var ld = {
                    ...logdetail,
                    newdesc: `Bill No : ${respbill.data}, Mob No : ${guestmob}, Name : ${guestname}`,
                    operation: "Bill", olddesc: "",
                }; setlogdetail(ld); saveLog(ld)
                // axios.post(`${hostlink}/checkin/blno/${respbill.data}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/bldate/${checkoutdate}/${checkinid}`)
                // axios.post(`${hostlink}/checkin/statusbill/${checkinid}`)
                // const rcodeString = roomdetail.map(item => item.rcode).join(',');
                // axios.post(`${hostlink}/room/updateroombulk/0/${rcodeString}`)
                toast("Save Sucessfull")
                setTimeout(() => {
                    window.location.assign("/SaleBill")
                }, 1000);
            })
            .catch(() => {
                console.log("Data Not Save")
            })
    }


    const [savedata, setSavedata] = useState({
        id: "",
        indate: "",
        mobno: "",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: "",
        rcode: "",
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        acccode: 0
    })

    const { mobno, gname, add1, add2, documentno, documentpath, indate, email, gstno, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, stday, discount, advance, paidby, remarks } = savedata;
    const [billdata, setBilldata] = useState({
        id: "",
        checkindate: "",
        checkoutdate: today.toISOString().split('T')[0],
        bldate: today.toISOString().split('T')[0],
        roomcode: "",
        guestroomname: "",
        roomrent: 0,
        extrabed: 0,
        extrabedch: 0,
        discountperc: 0,
        adddiscountamt: 0,
        additionalcharge: 0,
        advanceamount: 0,
        blpaidby: "",
        guestmob: "",
        guestname: "",
        guestadd1: "",
        guestadd2: "",
        guestdocno: "",
        guestemail: "",
        guestgstno: "",
        guestcompanyname: "",
        blremarks: "",
        bltotaldays: "",
        bltotal: "",
        blroomrent: "",
        discountpercamt: "",
        blgstperc: "",
        guestroomname: "",
        blgstamt: "",
        blamt: "",
        netpayamt: "",
        checkinid: "",
        shopresno: 0,
        shopid: usershopid,
        narration: "",
        roomdescription: "",
        roomdescriptioncode: "",
        acccode: 0,
        roomserviceamt: 0
    })
    const { checkinid, checkindate, acccode, checkoutdate, roomcode, roomrent, extrabed, extrabedch, discountperc, roomserviceamt, adddiscountamt, additionalcharge, advanceamount, blpaidby, guestmob, guestname, guestadd1, guestadd2, guestdocno, guestemail, guestgstno, guestcompanyname, blremarks, bltotaldays, bltotal, blroomrent, discountpercamt, blgstperc, blgstamt, blamt, netpayamt } = billdata;


    const billtotal = (e) => {
        var value = bl_Tot_1
        var ld = {
            ...billdata, bltotaldays: bl_Tot_1,
            bltotal: (value * (Number(roomrent) + (extrabed * extrabedch))) - Number((value * (Number(roomrent) + (extrabed * extrabedch))) * discountperc / 100),
            bldate: document.getElementById("checkoutdate").value,
            blroomrent: bl_RoomRent,
            discountpercamt: bl_Disc,
            blgstamt: bl_gstTotal,
            bltotal: (bl_RoomRent - bl_Disc),
            blamt: ((((bl_RoomRent - bl_Disc) + (bl_gstTotal) + roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)),
            netpayamt: (((((bl_RoomRent - bl_Disc) + (bl_gstTotal) + roomserviceamt) + Number(additionalcharge)) - Number(adddiscountamt)) - Number(advanceamount))
        }
        setBilldata(ld);
        setIsModalOpenEdit(false);
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const onChangevalueremarks = (e) => {
        setcancelrem(e.target.value);
    }
    const onChangevaluebill = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setBilldata({ ...billdata, [name]: sanitizedValue });
    }

    const updateStatus = async (e) => {
        let x = document.getElementById("frm_checkoutdetail")
        x.style.display = "block"
        await axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                setBilldata(Response.data)
                console.log(Response.data)

                var ld = {
                    ...logdetail,
                    olddesc: `Mob No : ${Response.data.mobno}, Name : ${Response.data.gname}, Rent : ${Response.data.rent}, Ex Bed : ${Response.data.exbed}, Bed Ch : ${Response.data.exbedch} , Advance : ${Response.data.advance}`,
                    operation: "Check-Out"
                }
                setlogdetail(ld);

                axios.get(`${hostlink}/getchehinroomDetail/${Response.data.checkinid}`)
                    .then((resp) => {
                        setroomdetail(resp.data)

                    })
            })

            .catch((res) => { console.log("Data Not find") })
    }
    const deleteData = async (e) => {

        if (cancelrem === "") {
            toast.error("Please Enter Delete Reason");
            document.getElementById("cancelrem").focus();
            return; // Return early if no cancel reason
        }

        try {

            const response = await axios.post(`${hostlink}/RestbillviewCancel/${usershopid}/${deletemsg}/1/${cancelrem}`);
            handleCloseModalCancel();
            toast.success("Delete Successful");
            setRefreshData(true);
            console.log(response.data);
            window.location.assign("/RestBillView")
        } catch (error) {
            console.error("Error canceling bill:", error);
            toast.error("Failed to cancel bill. Please try again.");
        }
        // await axios.post(`${hostlink}/Restbillview/${usershopid}/${deletemsg}/1`)
        //     .then((responce) => {
        //         toast.success("Delete Sucessfull")
        //         setInterval(() => {
        //             handleCloseModal();
        //             window.location.assign("/RestBillView")
        //             setRefreshData(true)
        //         }, 1000);
        //     })
        //     .catch((resp) => { console.log("Data Not Fatch..") })
    }
    const cancelBill = async (e) => {
        if (cancelrem === "") {
            toast.error("Please Enter Cancel Reason");
            document.getElementById("cancelrem").focus();
            return; // Return early if no cancel reason
        }

        try {
            const response = await axios.post(`${hostlink}/RestbillviewCancel/${usershopid}/${deletemsg}/2/${cancelrem}`);
            handleCloseModalCancel();
            toast.success("Cancel Successful");
            setRefreshData(true);
            console.log(response.data);
        } catch (error) {
            console.error("Error canceling bill:", error);
            toast.error("Failed to cancel bill. Please try again.");
        }
    };

    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"

        setRefreshData(false)
        axios.get(`${hostlink}/${usershopid}/Restbillview/0/${blfromdate}/${bltodate}`)
            .then((Response) => {
                console.log("Data Fatched")

                setData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
        axios.get(`${hostlink}/rtmas`)
            .then((Response) => {
                setRoomTYpe(Response.data)
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/${usershopid}/accmas`)
            .then((Response) => {
                setAccCode(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }, [refreshData, usershopid])
    return (
        <div className='p-1 ' >

            {/* /// Check In  */}
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <h5 className='font-monospace text-primary'> <i class="fa-solid fa-right-from-bracket"></i> <span id='pagemode' > Check-OUT [ New ]</span></h5>

                <div class="col-md-12 text-end">


                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-database"></i> Bill Details</h5>
                    </div>
                    <div className='col-md-3 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />

                    </div>

                    <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                        </span>
                        <span> <input type="date" id="blfromdate" name="blfromdate" onChange={(e) => { bldatechange(e); }} value={blfromdate} className="form-control" /></span>


                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="bltodate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                        </span>
                        <span>
                            <input type="date" id="bltodate" name="bltodate" onChange={(e) => { bldatechange(e); }} value={bltodate} className="form-control" />
                        </span>
                    </div>


                    <div style={{ height: "80vh", verticalAlign: 'middle', height: '100%' }} className='col-12 '>

                        <table className="table table-hover table-striped" >
                            <thead>
                                <tr style={{ height: '100%' }}>

                                    <th scope="col" className="f-size-on-mobile" >BlNo</th>
                                    <th scope="col" className="f-size-on-mobile">Bill <span className="hide-on-mobile" >Date</span>  <span className="detail-on-mobile"> Details</span> </th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    {/* <th scope="col" className="hide-on-mobile">Type</th> */}
                                    {/* <th scope="col" className="hide-on-mobile">Table</th> */}
                                    <th scope="col" className="hide-on-mobile">Bill Amount</th>
                                    <th scope="col" className="hide-on-mobile">Guest Mob</th>
                                    <th scope="col" className="hide-on-mobile">Guest Name</th>
                                    <th scope="col" className="f-size-on-mobile align-self-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (
                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                        <td className="f-size-on-mobile align-self-center text-center" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.shopvno}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <span className="detail-on-mobile"><b> Bill Date : {res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>  <span className='hide-on-mobile'>{res.SaleMasDTO.bldate ? new Date(res.SaleMasDTO.bldate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Ord Type : {res.bltype} <br />
                                                Guest : {res.SaleMasDTO.custname} <br /> Mob : {res.SaleMasDTO.custmob} <br /> Table : {res.tablename},
                                                Gst Amount : {res.SaleMasDTO.totgst}  <br />
                                                <span style={{ color: "red" }}> <b>Bill Amount : {res.SaleMasDTO.totblamt} </b></span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.entrytime}</td>
                                        {/* <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.bltype}</td> */}
                                        {/* <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.tablename}</td> */}
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.totblamt}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.custmob}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.SaleMasDTO.custname}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.SaleMasDTO.shopvno) }}><i class="fa-solid fa-circle-info"></i><span className="hide-on-mobile"></span></Link> &nbsp;

                                            {(userType === 0 || appState[1]?.[0]?.billview?.charAt(3) === '1') ? (<Link onClick={(e) => { handleOpenModal(res.SaleMasDTO.id, res.SaleMasDTO.shopvno, 1) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"></span></Link>) : null} &nbsp; <div className='show-on-mobile'><p></p></div>
                                            <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdataPrint(res.SaleMasDTO.shopvno) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile"></span></Link> &nbsp;
                                            <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.SaleMasDTO.shopvno) }}><i class="fa-regular fa-eye"></i> <span className="hide-on-mobile"></span></Link> &nbsp;
                                            <Link onClick={(e) => { handleOpenModalCancel(res.SaleMasDTO.id, res.SaleMasDTO.shopvno, 2) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-ban"></i>  <span className="hide-on-mobile"></span></Link>&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpenEdit} onRequestClose={handleCloseModalEdit} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Edit Bill ? <br /> Room No : {blno} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { billtotal(1); }}>Yes</button> <button className="no-button" onClick={handleNoClickEdit}>No</button></div></div>
            </Modal>


            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this Bill No : {deletemsg}?</p>
                    <input type="text" onChange={(e) => { onChangevalueremarks(e) }} name='cancelrem' value={cancelrem} class="form-control" id="cancelrem" placeholder='Delete Reason' />
                    <div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(blno) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>

            <Modal isOpen={isModalOpenCancel} onRequestClose={handleCloseModalCancel} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div clas><p className='text-bg-danger'>Are you sure you want to Cancel this Bill No : {deletemsg} ?</p><div className="button-container">

                    <input type="text" onChange={(e) => { onChangevalueremarks(e) }} name='cancelrem' value={cancelrem} class="form-control" id="cancelrem" placeholder='Cancel Reason' /> <br />

                    <button className="yes-button" onClick={(e) => { cancelBill(blno) }}>Yes</button>
                    <button className="no-button" onClick={handleNoClickCancel}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default RestBillView
